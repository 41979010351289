<template>
  <div>
    <md-card class="md-card-global-sales">
      <md-card-header class="md-card-header-icon md-card-header-blue">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
        <md-button
          class="md-primary float-right md-round md-sm"
          style="margin-top: -20px"
          :disabled="!tableHasContent"
          @click="onDownload"
        >
          <md-icon>get_app</md-icon>
          Download as XLS
        </md-button>
      </md-card-header>

      <md-card-content>
        <div class="md-layout mb-5">
          <div class="md-layout-item md-size-25">
            <form-date
              v-model="filters.prog_start_from"
              label="Programme Start From"
            />
          </div>
          <div class="md-layout-item md-size-25">
            <form-date
              v-model="filters.prog_start_to"
              label="Programme Start To"
            />
          </div>

          <div class="md-layout-item md-size-25">
            <form-date
              v-model="filters.test_finish_from"
              label="Test Finished From"
            />
          </div>
          <div class="md-layout-item md-size-25">
            <form-date
              v-model="filters.test_finish_to"
              label="Test Finished To"
            />
          </div>

          <div class="md-layout-item md-size-100">
            <md-button
              class="md-success md-sm w-100"
              @click="onFilter"
            >
              Filter
            </md-button>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <vue-table
              v-if="vTable.headers"
              ref="vtable"
              :values="vTable.values"
              :headers="vTable.headers"
              :actions="vTable.actions"
              :options="{
                tableClass:'table'
              }"
              @changed="getData"
            />
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import moment from 'moment';
import VueTable from '@/components/Tables/VueTable.vue';
import { FormDate } from '@/components/Inputs';
import downloadFile from '@/utils/downloadFile';

export default {
  components: {
    FormDate,
    VueTable,
  },
  data: () => ({
    vTable: {
      headers: [
        {
          title: 'student.student_number',
          mask: 'student no.',
          sortable: true,
        },
        {
          title: 'student.full_name',
          mask: 'name',
          sortable: true,
        },
        {
          title: 'student.programme.start_date',
          mask: 'prog. start date',
          sortable: true,
          dateFormat: true,
        },
        {
          title: 'finished_at',
          mask: 'test finished at',
          sortable: true,
          dateFormat: true,
        },
        {
          title: 'score',
          sortable: true,
        },
      ],
      values: {},
      actions: [],
    },
    filters: {
      prog_start_from: null,
      prog_start_to: null,
      test_finish_from: null,
      test_finish_to: null,
    },
    loading: false,
    params: {},
  }),
  computed: {
    tableHasContent() {
      return this.vTable.values?.data?.length > 0;
    },
  },
  mounted() {
    this.$refs.vtable.init();
  },
  methods: {
    onFilter() {
      this.$refs.vtable.init();

      this.params = { ...this.filters, ...this.params };
    },
    getData(params) {
      this.loading = true;
      this.filters = {
        ...this.filters,

        // We add the params to filters,
        // to be able to download the XLS filtered
        // by the following params (sortBy, sortDir, search)
        page: params.page,
        per_page: params.per_page,
        sort_by: params.sortBy,
        sort_dir: params.sortDir,
        search: params.search,
      };

      this.params = { ...this.filters, ...params };

      this.request('placement_test/report', 'get', { ...this.params }, ({ data }) => {
        this.vTable.values = data;
      }, () => {
        this.loading = false;
      });
    },
    onDownload() {
      this.loading = true;
      this.request('placement_test/report', 'download', {
        ...this.params,
        download: true,
      }, ({ data }) => {
        const filename = `placement_test_report_${moment().format('YYYY_MM_DD')}.xlsx`;
        downloadFile(data, filename);
      }, () => {
        this.loading = false;
      });
    },
  },
};
</script>
